import React from "react";
import { ConversationStep } from "../../interfaces";
import Theme from "../../interfaces/Theme";
import { sendEmail, sendNewLead } from "../../util";

interface IProps {
  values?: any;
  step?: ConversationStep;
  triggerNextStep?: (data?: {
    value?: string | number;
    trigger: string;
  }) => void;
}

interface IState {
  values: any;
  theme: Theme;
}

class SendNewLead extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      values: props?.values,
      theme: props?.step?.theme!,
    };
  }

  async componentDidMount() {
    //FOR CHARTWELL
    if (this.state.theme.apiKey === "d83355c3-53dd-4b14-9c7d-fba365ff0819") {
      await sendEmail(this.state.values, this.state.theme);
    }

    await sendNewLead(this.state.values, this.state.theme);
    this.props.triggerNextStep!({
      trigger: this.props.step!.trigger,
    });
  }

  render() {
    return (
      <>
        {this.props?.step?.id !== "blank_send" && (
          <div className="conversation-message-bubble">
            <div
              className="conversation-message-bubble-text"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                marginBottom: "20px",
              }}
            >
              {
                this.state.theme.apiKey === "dd1a7b84-a16c-4d16-ae74-9c6f2e370918" ? 
                null : "No Problem."
              }
            </div>
          </div>
        )}
      </>
    );
  }
}

export default React.memo(SendNewLead);
