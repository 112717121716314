import { ArrowForward } from "@mui/icons-material";
import { Button, Grid, TextField, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../AppContext";
import { sendAppointmentEmail } from "../util";

const GetNextDates = require("get-next-dates");

interface BookAppointmentProps {
    values?: any;
    setDataValues?: Function;
}

const BookAppointment: React.FC<BookAppointmentProps> = () => {
    const theme = useTheme();

    const { values, setDataValues } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isBookSuccess, setIsBookSuccess] = useState(false);
    const [dateList, setDateList] = useState<string[]>([]);
    const [isDateSelected, setIsDateSelected] = useState(false);
    const textStyle = {
        padding: "16px 0px",
        borderRadius: 8,
        display: "inline-block",
        border: `1px solid ${theme.palette.primary.main}`,
        marginTop: 0,
        marginRight: 6,
        marginBottom: 10,
        fontWeight: "bold",
        verticalAlign: "top",
    };

    const hoverStyle = {
        background: theme.palette.primary.main,
        color: "#fff",
    };

    const textHoverStyle = {
        minWidth: "58px !important",
        "&:hover": hoverStyle,
    };

    const dateListItemStyle = {
        minWidth: 86,
        minHeight: 95,
    };

    const textFieldStyle = {
        background: "#FFF",
        outline: "none",
        borderRadius: 36,
        border: "1px solid #E7E7E7",
        ".MuiInputBase-root": {
            background: "#FFF",
            outline: "none",
            borderRadius: 36,
            border: "1px solid #E7E7E7",
        },
        fieldset: {
            outline: "none",
            borderRadius: 36,
            border: "1px solid #E7E7E7",
        },
    };

    const timeOfDayStyle = {
        width: "100%",
    };

    const bookAppointment = async () => {
        try {
            setIsLoading(true);

            await sendAppointmentEmail(values, theme?.custom);

            setIsBookSuccess(true);
            if (
                theme?.custom?.apiKey === "aca36806-760b-4b14-84a4-6e9e2757f237"
            ) {
                //@ts-ignore
                window.dataLayer = window.dataLayer || [];
                //@ts-ignore
                window.dataLayer.push({
                    event: "ai_assistant",
                    action: "appointment_booked",
                });

                console.log(`//@ts-ignore
                window.dataLayer = window.dataLayer || [];
                //@ts-ignore
                window.dataLayer.push({
                    event: "ai_assistant",
                    action: "appointment_booked",
                });`);

                console.log(
                    `window.dataLayer: `,
                    //@ts-ignore
                    window.dataLayer
                );
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const getDates = (date: string) => {
        const dates = GetNextDates(7, new Date(date)).filter(
            (date: string) => moment(date).day() > 0 && moment(date).day() < 6
        );

        setDateList([...dateList, ...dates]);
    };

    const renderDateList = () => {
        return (
            <div id="date-list-section" style={{ marginBottom: 20 }}>
                {dateList.map((date) => (
                    <label
                        htmlFor={moment(date).format("DD/MM/YYYY")}
                        style={{ textAlign: "center", cursor: "pointer" }}
                        key={moment(date).format("DD/MM/YYYY")}
                        onClick={() => {
                            setIsDateSelected(true);
                            setDataValues!({
                                appointment_date:
                                    moment(date).format("YYYY-MM-DD"),
                            });
                        }}
                    >
                        <Typography
                            component="div"
                            style={{ ...textStyle, ...dateListItemStyle }}
                            sx={{
                                ...textHoverStyle,
                                ...(values?.appointment_date?.includes(
                                    moment(date).format("YYYY-MM-DD")
                                )
                                    ? hoverStyle
                                    : {}),
                            }}
                        >
                            <Typography
                                variant="h5"
                                style={{ fontWeight: "bold" }}
                            >
                                {moment(date).format("ddd")}
                            </Typography>
                            <Typography
                                variant="h2"
                                style={{ fontWeight: "bold" }}
                            >
                                {moment(date).date()}
                            </Typography>
                            <Typography
                                variant="h5"
                                style={{ fontWeight: "bold" }}
                            >
                                {moment(date).format("MMM")}
                            </Typography>
                        </Typography>
                    </label>
                ))}
                {dateList.length && (
                    <Typography
                        component="div"
                        style={{
                            ...textStyle,
                            ...dateListItemStyle,
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                        sx={textHoverStyle}
                        onClick={() => getDates(dateList[dateList.length - 1])}
                    >
                        <ArrowForward />
                    </Typography>
                )}
            </div>
        );
    };

    const renderTimeOfDay = () => {
        const timeOfDayList = ["Morning", "Afternoon", "Evening"];

        return (
            <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                {timeOfDayList.map((timeOfDay) => (
                    <Grid item xs={12} md={4} key={timeOfDay}>
                        <label
                            htmlFor={timeOfDay}
                            style={{ textAlign: "center", cursor: "pointer" }}
                            onClick={() => {
                                setDataValues!({
                                    appointmentTimeOfDay: timeOfDay,
                                });
                            }}
                        >
                            <Typography
                                className="timeOfDay"
                                component="span"
                                style={{ ...textStyle, ...timeOfDayStyle }}
                                sx={{
                                    ...textHoverStyle,
                                    ...(values?.appointmentTimeOfDay ===
                                    timeOfDay
                                        ? hoverStyle
                                        : {}),
                                }}
                            >
                                {timeOfDay}
                            </Typography>
                        </label>
                    </Grid>
                ))}
            </Grid>
        );
    };

    const renderTime = () => {
        const time: Record<any, string[]> = {
            morning: ["09:00", "10:00", "11:00"],
            afternoon: ["12:00", "13:00", "14:00", "15:00"],
            evening: ["16:00", "17:00"],
        };

        if (!values?.appointmentTimeOfDay) return null;

        return (
            <Grid container spacing={2} sx={{ marginBottom: "40px" }}>
                {time[values?.appointmentTimeOfDay.toLowerCase()].map(
                    (hour) => (
                        <Grid item xs={12} md={3} key={hour}>
                            <label
                                htmlFor={hour}
                                style={{
                                    textAlign: "center",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setDataValues!({
                                        appointmentTime: hour,
                                        appointment_date: `${values.appointment_date}T${hour}:00Z`,
                                    });
                                }}
                            >
                                <Typography
                                    className="time"
                                    component="span"
                                    style={{ ...textStyle, ...timeOfDayStyle }}
                                    sx={{
                                        ...textHoverStyle,
                                        ...(values?.appointmentTime === hour
                                            ? hoverStyle
                                            : {}),
                                    }}
                                >
                                    {hour}
                                </Typography>
                            </label>
                        </Grid>
                    )
                )}
            </Grid>
        );
    };

    const renderAppointmentText = () => {
        //FitschandFitsch
        if (
            theme?.custom?.apiKey === "e391b696-0125-4fe7-a463-a998667352c9" ||
            theme?.custom?.apiKey === "e391b696-0125-4fe7-a463-a998667352c0" ||
            theme?.custom?.apiKey === "e391b696-0125-4fe7-a463-a998667352c1" ||
            theme?.custom?.apiKey === "e391b696-0125-4fe7-a463-a998667352c2"
        ) {
            return (
                <Typography
                    component="p"
                    fontWeight={400}
                    fontSize={15}
                    lineHeight="22px"
                    marginBottom="26px"
                    color={theme?.custom?.customization?.parkerSlideTextColor || '#000'}
                >
                    Choose a date and time below, one of the team will reach out
                    to confirm your appointment. By proceeding you consent to
                    allow Fitch & Fitch to store and process the personal
                    information submitted and agree with our{" "}
                    <a
                        href={theme?.custom?.links?.privacyPolicyURL}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>
                    .
                </Typography>
            );
        }

        return (
            <Typography
                component="p"
                fontWeight={400}
                fontSize={15}
                lineHeight="22px"
                marginBottom="26px"
                color={theme?.custom?.customization?.parkerSlideTextColor || '#000'}
            >
                Choose a date and time below, one of the team will then reach
                out to confirm your appointment and run you through how.{" "}
                {/* {theme?.custom?.customization?.companyName || "Meet Parker"} will fix
        the problems your business may be facing. */}
            </Typography>
        );
    };

    useEffect(() => {
        getDates(moment().toString());
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    return (
        <Box padding="35px 0px">
            <Typography
                fontWeight={700}
                fontSize={36}
                lineHeight="48px"
                color={theme?.custom?.customization?.parkerSlideTextColor || '#000'}
            >
                Appointment Booker
            </Typography>
            {renderAppointmentText()}
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} marginBottom="10px">
                    <Typography
                        fontWeight={700}
                        fontSize={16}
                        lineHeight="23px"
                        color={theme?.custom?.customization?.parkerSlideTextColor || '#000'}
                    >
                        First Name
                    </Typography>
                    <TextField
                        name="firstName"
                        type="text"
                        fullWidth
                        sx={textFieldStyle}
                        value={values?.applicant1_firstName || ""}
                        onChange={(e) => {
                            setDataValues!({
                                applicant1_firstName: e.target.value,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} marginBottom="10px">
                    <Typography
                        fontWeight={700}
                        fontSize={16}
                        lineHeight="23px"
                        color={theme?.custom?.customization?.parkerSlideTextColor || '#000'}
                    >
                        Last Name
                    </Typography>
                    <TextField
                        name="lastName"
                        type="text"
                        fullWidth
                        sx={textFieldStyle}
                        value={values?.applicant1_lastName || ""}
                        onChange={(e) => {
                            setDataValues!({
                                applicant1_lastName: e.target.value,
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6} marginBottom="10px">
                    <Typography
                        fontWeight={700}
                        fontSize={16}
                        lineHeight="23px"
                        color={theme?.custom?.customization?.parkerSlideTextColor || '#000'}
                    >
                        Email
                    </Typography>
                    <TextField
                        name="email"
                        type="email"
                        fullWidth
                        sx={textFieldStyle}
                        value={values?.applicant1_email || ""}
                        onChange={(e) => {
                            setDataValues!({
                                applicant1_email: e.target.value,
                            });
                        }}
                    />
                </Grid>
            </Grid>
            <Box
                sx={{
                    padding: "15px 20px",
                    width: "60%",
                    minHeight: "355px",
                    background: "#FFF",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
                    borderRadius: "10px",
                    marginBottom: "30px",
                    "@media screen and (max-width: 767px)": {
                        width: "100%",
                    },
                }}
            >
                {renderDateList()}
                {renderTimeOfDay()}
                {renderTime()}
                <Button
                    variant="contained"
                    disableElevation
                    disabled={
                        isLoading ||
                        isBookSuccess ||
                        !values?.applicant1_email ||
                        !values?.applicant1_firstName ||
                        !values?.applicant1_lastName ||
                        !values?.appointmentTime ||
                        !values?.appointmentTimeOfDay ||
                        !isDateSelected
                    }
                    sx={{
                        backgroundColor: "#36BA43",
                        padding: "10px 17px",
                        minWidth: "200px",
                        borderRadius: 20,
                        color: "#FFF",
                        fontWeight: 700,
                        fontSize: 14,
                    }}
                    onClick={async () => {
                        await bookAppointment();
                    }}
                >
                    Schedule
                </Button>
                {isBookSuccess ? (
                    <Typography
                        marginTop="10px"
                        sx={{
                            padding: "10px",
                            background: "#01c851",
                            borderRadius: "8px",
                            color: "#FFF",
                        }}
                    >
                        You're all booked in - You will receive an email
                        confirmation shortly.
                    </Typography>
                ) : null}
            </Box>
        </Box>
    );
};

export default BookAppointment;
