import React, { useContext, useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import ChatMessage from "../components/ChatMessage";
import { ConversationStep } from "../interfaces/ConversationStep";

import * as _ from "lodash";
import withRoot from "../withRoot";

import FullSteps from "../config/full";
import { useTheme, Box, Container } from "@mui/material";
import Header from "../components/Header";
import { AppContext } from "../AppContext";
import { initializeGoogleAnalytics, trackMPEvent } from "../util";
import platform from "platform";
import Onboarding from "./Onboarding";

interface ConversationProps {
  stepsList: ConversationStep[];
  values: any;
  setDataValues: Function;
}

const Conversation: React.FC<ConversationProps> = () => {
  const { values, setDataValues } = useContext(AppContext);

  const PageBackground = "https://d365pq86x330zn.cloudfront.net/764pa07p-6811-4851-71p9-142a2a767775.png";

  const theme = useTheme();
  const [stepsList] = useState<ConversationStep[]>(FullSteps(theme?.custom));
  const [currentPage, setCurrentPage] = useState<ConversationStep[]>([]);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const header = theme?.custom?.appConfig?.header === "false";

  const getPages = (
    step: ConversationStep,
    pages: ConversationStep[] = []
  ): ConversationStep[] => {
    if (step) {
      let nextStep = getTriggeredStep!(step?.trigger, "")!;

      while (nextStep?.disable && nextStep?.disable(values)) {
        nextStep = getTriggeredStep!(nextStep?.trigger, "")!;
      }

      if (!step?.input && !step?.options?.length) {
        step.theme = theme?.custom!;
        return getPages(nextStep, [...pages, step]);
      }

      const components = pages.filter((page) => page.component).filter(Boolean);

      if (components.length && step.component) {
        step.theme = theme?.custom!;
        return pages;
      }

      step.theme = theme?.custom!;
      pages.push(step);
    }

    return pages;
  };

  const getTriggeredStep = (trigger: any, value: any, currentStep: any = {}) => {
    if (currentStep) {
      if (currentStep?.id) {
        if (currentStep?.id === "talk_with_advisor") {
          let nextRet = _.find(stepsList, (step) => {
            return (
              step.id ===
              (typeof trigger === "function" ? trigger({
                ...values,
                talk_with_advisor: value
              }, value) : trigger)
            );
          });
          if (nextRet?.id === "appointment_api") {
            nextRet = {
              ...nextRet,
              trigger: (typeof nextRet.trigger === "function" ? nextRet.trigger({
                ...values,
                talk_with_advisor: value
              }, value) : nextRet.trigger)
            }
          }
          return nextRet;
        }
      }
    }
    return _.find(stepsList, (step) => {
      return (
        step.id ===
        (typeof trigger === "function" ? trigger(values, value) : trigger)
      );
    });
  };

  const getTextNextStep = async (
    step: ConversationStep,
    data: { trigger: string; value: string }
  ) => {
    const { value } = data;

    if (step?.custom_value) {
      const obj = await step?.custom_value(value, values);

      setDataValues({
        ...values,
        [obj?.key]: obj?.value,
      });
    } else {
      setDataValues({
        ...values,
        [step?.id!]: value,
      });
    }
  };

  const getOptionNextStep = async (
    step: ConversationStep,
    data: { trigger: string; value: string }
  ) => {
    const { value } = data;

    const options =
      typeof step.options === "function"
        ? await step.options(values)
        : step.options;
    const selectedOption = options?.find((o) => o.value === value);

    if (selectedOption) {
      if (step?.custom_value) {
        const obj = await step?.custom_value(value, values);

        setDataValues({
          ...values,
          [obj?.key]: obj?.value,
        });
      } else {
        setDataValues({
          ...values,
          [step?.id!]: value,
        });
      }
    } else {
      await getTextNextStep(step, data);
    }
  };

  const addCommasToNumber = (text: string) => {
    // Convert the text to a number and check if it's a valid number
    const number = parseFloat(text);
    if (!isNaN(number)) {
      // Format the number with commas
      return number.toLocaleString();
    } else {
      // Return the original text if it's not a valid number
      return text;
    }
  };

  const triggerNextPage = async (
    currentStep: ConversationStep,
    data: { trigger: string; value: string }
  ) => {
    const { trigger, value } = data;

    if (currentStep?.options) {
      await getOptionNextStep(currentStep, data);
    } else {
      await getTextNextStep(currentStep, data);
    }

    console.log('=== trigger', trigger, currentStep, data)

    if (trigger) {
      console.log('== beforegetTriggeredStep', trigger, value, data, currentStep)
      const nextStep = getTriggeredStep(trigger, value, currentStep) as ConversationStep;
      console.log('== nextStep', nextStep, value)

      if (trigger === "appointment_page") {
        setShowOnboarding(true);
        //navigate(`/onboarding?apiKey=${theme?.custom?.apiKey}`);
      } else {
        let modGetPages = getPages(nextStep).map((modGetPage) => {
          if (
            typeof modGetPage?.message === "string" &&
            modGetPage?.message?.match(/{previousValue}/g)
          ) {
            let modGetPageMessage = modGetPage.message || "";
            return {
              ...modGetPage,
              message: modGetPageMessage.replace(
                /{previousValue}/g,
                addCommasToNumber(value)
              ),
            };
          } else {
            return modGetPage;
          }
        });
        console.log('== currentPage', modGetPages)
        setCurrentPage(modGetPages);
      }
    }
  };

  const renderPage = () => {
    if (showOnboarding) {
      return <Onboarding />;
    } else {
      return (
        <Box
          id="parker-slide"
          sx={{
            minHeight: "100vh",
            backgroundImage: `url("${
              theme?.custom?.customization?.parkerSlideBG
                ? theme?.custom?.customization?.parkerSlideBG
                : PageBackground
            }")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            ...(theme.custom?.apiKey === "370c8885-59dc-40c5-ba4e-2ce4a3ec01b4" && {
              backgroundPosition: "center",
            }),
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              paddingTop: "120px",
              paddingBottom: "90px",
            }}
          >
            <ChatMessage
              currentPage={currentPage}
              triggerNextPage={triggerNextPage}
            />
          </Container>
        </Box>
      );
    }
  };

  useEffect(() => {
    const checkAndAddGTM = () => {
      if (values?.theme?.apiKey === "aca36806-760b-4b14-84a4-6e9e2757f237") {
        const script = document.createElement('script');
        script.async = true;
        script.innerHTML = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-WKWQLL52');
        `;
        document.head.appendChild(script);
      }
    };

    checkAndAddGTM();
  }, [values]);

  useEffect(() => {
    const analytics = async () => {
      await trackMPEvent(theme.custom?.apiKey!, {
        pageVisit: {
          deviceBrowser: platform.name,
          deviceMobile: platform.product,
          deviceOs: platform.os?.toString(),
        },
      });

      if (
        Array.isArray(theme?.custom?.googleAnalyticsTrackingId) &&
        theme?.custom?.googleAnalyticsTrackingId?.length
      ) {
        initializeGoogleAnalytics(theme?.custom?.googleAnalyticsTrackingId);
      }
    };

    analytics();
    setCurrentPage(getPages(stepsList[0]));
    /*eslint-disable react-hooks/exhaustive-deps*/
  }, []);

  return (<>
    {values?.theme?.apiKey === "aca36806-760b-4b14-84a4-6e9e2757f237" && (
      <Helmet>
        <noscript>
          {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WKWQLL52"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
        </noscript>
      </Helmet>
    )}
    <React.Fragment>
      <style>{`\
    ${
      theme?.custom?.customization?.fontFamily
        ? `
      #parker-slide {
          font-family: ${theme?.custom?.customization?.fontFamily} !important;
      }     
    `
        : ""
    }
  `}</style>
      {!header && <Header />}

      {renderPage()}
    </React.Fragment>
  </>);
};

export default Conversation;
