import Header from "../components/Header";
import withRoot from "../withRoot";

import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import Sidebar from "../components/Sidebar";
import { useContext, useEffect, useState } from "react";
import BookAppointment from "../components/BookAppointment";
import { AppContext } from "../AppContext";
import { sendNewLead } from "../util";

const drawerWidth = 270;
const PageBackground = "https://d365pq86x330zn.cloudfront.net/764pa07p-6811-4851-71p9-142a2a767775.png";

const Onboarding = () => {
  const [open, setOpen] = useState(true);
  const page = {
    displayName: "Appointment",
    element: <BookAppointment />,
  };
  const [pages] = useState([page]);
  const [currentPage] = useState(page);

  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = () => setOpen(!open);

  const { values } = useContext(AppContext);

  //@ts-ignore
  const header = theme?.custom?.appConfig?.header === "false";

  const renderAppointment = () => {
    if (theme?.custom?.links?.calendarURL) {
      return (
        <iframe
          id="mp-onboarding-iframe"
          style={{
            border: "none",
            height: "calc(100vh - 80px)",
            marginTop: "80px",
            ...(matchDownMd
              ? {
                  marginLeft: 0,
                  width: "100%",
                }
              : {
                  marginLeft: `${drawerWidth}px`,
                  width: `calc(100% - ${drawerWidth}px)`,
                }),
          }}
          src={theme?.custom?.links?.calendarURL}
        ></iframe>
      );
    }

    return (
      <Container
        maxWidth="lg"
        sx={{
          paddingTop: "90px",
          paddingBottom: "90px",
          ...(theme?.custom?.links?.calendarURL && {
            paddingLeft: "0px !important",
            paddingRight: "0px !important",
            paddingBottom: "0px !important",
          }),
          ...(matchDownMd
            ? {
                marginLeft: 0,
                width: "100%",
              }
            : {
                marginLeft: `${drawerWidth}px`,
                width: `calc(100% - ${drawerWidth}px)`,
              }),
        }}
      >
        {currentPage?.element}
      </Container>
    );
  };

  useEffect(() => {
    const sendLeadEmail = async () => {
      await sendNewLead(values, theme?.custom);
    };

    if (theme?.custom?.links?.calendarURL) {
      sendLeadEmail();
    }
  }, [theme?.custom?.links?.calendarURL]);

  return (
    <>
      {!header && <Header toggleDrawer={toggleDrawer} />}
      <Sidebar
        drawerWidth={drawerWidth}
        open={open}
        pages={pages}
        toggleDrawer={toggleDrawer}
        currentPage={currentPage}
      />
      <Box
        sx={{
          minHeight: "100vh",
          backgroundImage: `url("${
            theme?.custom?.customization?.parkerSlideBG
              ? theme?.custom?.customization?.parkerSlideBG
              : PageBackground
          }")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: `${
            theme.custom?.apiKey === "370c8885-59dc-40c5-ba4e-2ce4a3ec01b4"
              ? "bottom right"
              : "center"
          }`,
        }}
      >
        {renderAppointment()}
      </Box>
    </>
  );
};

export default Onboarding;
